import React, { useEffect } from "react";
import { Container, Navbar, Nav, Image } from "react-bootstrap";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Home from "./Home";
import Actualites from "./Actualites";
import MentionLegale from "./MentionLegale";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (hash === "") {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          window.scrollTo({
            top: element.offsetTop - 90, // Offset for navbar height
            behavior: "smooth",
          });
        }
      }, 0);
    }
  }, [pathname, hash]); // Triggered on change of pathname or hash

  return null;
}

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar bg="light" expand="lg" className="shadow-sm py-3 fixed-top">
          <Container fluid>
            <Navbar.Brand as={Link} to="/home">
              <h1 className="font-weight-bold text-dark">
                Sauvegarde du Temple de Sommières
              </h1>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Link
                  as={HashLink}
                  to="/home#Leprojet"
                  smooth
                  className="mx-3 text-dark"
                >
                  Le Projet
                </Nav.Link>
                <Nav.Link
                  as={HashLink}
                  to="/home#Lhistoire"
                  smooth
                  className="mx-3 text-dark"
                >
                  L'Histoire
                </Nav.Link>
                <Nav.Link
                  as={HashLink}
                  to="/home#Lassociation"
                  smooth
                  className="mx-3 text-dark"
                >
                  L'Association
                </Nav.Link>
                <Nav.Link
                  as={HashLink}
                  to="/home#CommentSoutenir"
                  smooth
                  className="mx-3 text-dark"
                >
                  Soutenir Le Projet
                </Nav.Link>
                <Nav.Link as={Link} to="/actualites" className="mx-3 text-dark">
                  Actualités
                </Nav.Link>
                <Nav.Link
                  as={HashLink}
                  to="/home#Contact"
                  smooth
                  className="mx-3 text-dark"
                >
                  Contact
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <ScrollToTop />
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/actualites" element={<Actualites />} />
          <Route path="/mention-legale" element={<MentionLegale />} />
          <Route path="/" element={<Home />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

function Footer() {
  return (
    <footer className="text-center mt-4 py-4">
      <Container className="footer-content">
        <p>© {new Date().getFullYear()} Sauvegarde Du Temple De Sommieres</p>
        <Link to="/mention-legale" className="text-decoration-none legal-link">
          Mentions légales
        </Link>
      </Container>
    </footer>
  );
}

export default App;
