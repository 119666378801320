import React from "react";

function Actualites() {
  return (
    <div className="App" style={{ paddingTop: "100px" }}>
      {" "}
      <h1>Actualités</h1>
      <p>Très prochainement!</p>
    </div>
  );
}

export default Actualites;
