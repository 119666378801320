import React from "react";

function MentionLegale() {
  return (
    <div className="mentions-legales">
      <img
        src="./temple.jpg"
        alt="Temple de Sommières"
        className="header-image"
      />
      <div className="legal-content">
        <h1>Mentions Légales</h1>
        <p>
          Propriétaire du site : Association de Sauvegarde du Temple de
          Sommières.
        </p>
        <p>Adresse : 16 route de Montpellier, 30250 SOMMIERES</p>
        <p>Téléphone : 06 75 27 15 21</p>
        <p>
          Email :{" "}
          <a href="mailto:astsommieres@gmail.com">astsommieres@gmail.com</a>
        </p>
        <p>Ce site est hébergé sur Amazon Web Services, Inc.</p>
        <p>Directeur de la publication : Claire Daguzon, Raphael Cousin</p>
      </div>
    </div>
  );
}

export default MentionLegale;
