import React from "react";
import { Container, Navbar, Nav, Row, Col, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

function Home() {
  return (
    <div className="App">
      <Container>
        <Row id="Leprojet" className="align-items-center my-5">
          <Col md={6} className="mb-4">
            <Image
              src="temple3.jpg"
              alt="Temple de Sommières"
              fluid
              className="rounded"
            />
          </Col>
          <Col md={6}>
            <h2 className="mb-3">Le projet</h2>
            <p>
              Le temple de Sommières, un élément clé du patrimoine cultuel de la
              ville, nécessite une réhabilitation urgente. Des dégradations
              importantes, notamment de la toiture, ont entraîné des
              infiltrations d'eau et rendu le bâtiment inutilisable. Ce projet
              vise à restaurer le temple en commençant par des travaux cruciaux
              sur la toiture.
            </p>
          </Col>
        </Row>

        <Row id="Lhistoire" className="align-items-center my-5">
          <Col md={6} className="order-md-1">
            <h2 className="mb-3">L'Histoire</h2>
            <p>
              Le Temple Protestant "Au Livre," autrefois le Couvent des
              Cordeliers, est plus qu'un simple bâtiment; il est un témoin
              silencieux de l'histoire mouvementée de Sommières et de
              l'Occitanie. Fondé en 1223, peu après les tourments de la guerre
              des Albigeois et l'implantation de l'administration royale dans la
              ville, il a survécu à des siècles d'histoire française, tantôt
              paisibles, tantôt tumultueux.
            </p>
            <p>
              L'écho des prières des Cordeliers, les bruits de bottes des
              soldats pendant les guerres de religion et les hymnes de la
              communauté protestante résonnent encore dans ses murs. Détruit en
              1562, puis soigneusement reconstruit et achevé en 1710, le temple
              est un symbole de résilience et de foi inébranlable. Sa
              transformation en lieu de culte protestant en 1807 et les diverses
              modifications qui ont suivi reflètent l'évolution culturelle et
              spirituelle de la communauté qu'il sert.
            </p>
            <p>
              Le temple dévoile une architecture riche en histoire, composée
              d'une nef centrale autrefois flanquée de bas-côtés, et couronnée
              d'un magnifique campanile de pierre. À l'intérieur, une tribune
              majestueuse trône dans l'ancienne abside semi-circulaire, abritant
              un orgue à cylindres classé Monument Historique. Des détails tels
              que la chaire en bois ciré et le Livre sculpté sur la façade
              enrichissent ce joyau architectural.
            </p>
            <p>
              Le temple n'est pas seulement un lieu de culte, mais aussi un
              élément vital du patrimoine culturel de la région. Il s'inscrit
              dans le cadre de plusieurs circuits touristiques, notamment le
              "chemin européen des huguenots," et représente un potentiel
              significatif pour le développement touristique local et régional.
            </p>
          </Col>
          <Col md={6} className="mb-4 order-md-1">
            <Image
              src="organ2.jpg"
              alt="L'orgue historique du Temple de Sommières"
              fluid
              className="rounded"
            />
          </Col>
        </Row>
        <Row id="Lassociation" className="align-items-center my-5">
          <Col xs={12} md={6}>
            <Image src="logo_officiel_ASTS.png" alt="Logo de l'ASTS" fluid />
          </Col>
          <Col xs={12} md={6}>
            <h2 className="mt-4 mb-3">
              L'<strong>A</strong>ssociation de <strong>S</strong>auvegarde du{" "}
              <strong>T</strong>emple de <strong>S</strong>ommières
            </h2>
            <p>
              Propriété communale, le temple est resté ouvert jusqu’en 2023. Son
              état s’étant fortement dégradé, il a été décidé de fermer la
              grande salle de culte, devenue particulièrement dangereuse.
            </p>
            <p>
              M. Pierre Martinez, Maire de Sommières, et son conseil municipal
              ont témoigné de leur volonté de voir le temple restauré.
              Toutefois, les financements publics ne pourront pas couvrir
              l’ensemble des travaux nécessaires. Des personnes se sont donc
              mobilisées pour donner toutes ses chances au projet d’aboutir et
              L'<strong>A</strong>ssociation de <strong>S</strong>auvegarde du{" "}
              <strong>T</strong>emple de <strong>S</strong>ommières a été créée,
              conformément à la loi du 1er juillet 1901. L’ASTS, reconnue
              d’intérêt général, a pour objet de concourir à la mise en valeur
              du patrimoine architectural et mobilier du temple en contribuant
              au financement de sa restauration, sa conservation et la
              protection de son orgue. Elle vise à recueillir des fonds en
              particulier par le biais du mécénat et en organisant des
              manifestations culturelles.
            </p>
            <p>
              L’association est ouverte à toute personne désireuse de soutenir
              ces efforts.
            </p>
          </Col>
        </Row>

        <Row id="CommentSoutenir" className="mb-5 align-items-center">
          <Col xs={12} md={6}>
            <h2 className="mt-4 mb-3">Comment soutenir ce projet ?</h2>
            <p>
              Le coût total des travaux est important et vous pouvez contribuer
              à cette noble cause en faisant un don. Chaque petit geste compte !
            </p>
            <p>Les dons sont à faire via la Fondation du Patrimoine.</p>
            <p>
              Ils ouvrent droit à une réduction d'impôt qui peut aller jusqu'à
              75% du don.
            </p>
            <p>Vous trouverez toutes les informations en suivant ce lien :</p>
            <p>
              <a
                href="https://www.fondation-patrimoine.org/les-projets/restauration-du-temple-de-sommieres"
                target="_blank"
                className="btn btn-primary mt-2"
              >
                En savoir plus
              </a>
            </p>
            <p>Ou en flashant ce QR code:</p>
            <Image
              src="QRCode_FDP.png"
              alt="QR Code Fondation du Patrimoine"
              fluid
              className="my-2"
            />
          </Col>
          <Col xs={12} md={6}>
            <Image
              src="logo_fondation_du_patrimoine_cmjn.png"
              alt="Logo Fondation du Patrimoine"
              fluid
            />
          </Col>
        </Row>

        <Row id="Contact" className="mb-5">
          <Col>
            <h2 className="mt-4 mb-3">Contact</h2>
            <p>
              <strong>A</strong>ssociation de <strong>S</strong>auvegarde du{" "}
              <strong>T</strong>emple de <strong>S</strong>ommières
              <br />
              16 route de Montpellier
              <br />
              30250 SOMMIERES
              <br />
              <a href="mailto:astsommieres@gmail.com">astsommieres@gmail.com</a>
              <br />
              Mme Danièle MARTINEZ (Présidente)
              <br />
              06 75 27 15 21
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Home;
